<template>
    <div class="news page">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">    
                    <div v-if="promotions.length > 0" class="news__news-fresh">
                        <h2 class="h1">Promóciók</h2>
                        
                        <NewsItemBig 
                            v-for='pnews in promotions' :key="pnews.id" 
                            :news="pnews"
                            :isPromotion="true"
                        />
                    </div>           
                    <div v-if="featuredNews.length > 0" class="news__news-fresh">
                        <h1>Aktualitások</h1>
                        <NewsItemBig 
                            v-for='fnews in featuredNews' :key="fnews.id" 
                            :news="fnews"
                            :isPromotion="false"
                        />
                    </div>
                    <div v-if="allnews.length > 0" class="news__news-others">
                        <h2>További hírek</h2>
                        <b-row>
                            <b-col cols="12" lg="6" v-for='(news, nindex) in allnews' :key="news.id"  >
                                <NewsItem                                     
                                    :news="news"
                                    v-if='nindex < currentEnd'
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" v-if='allnews.length>otherNewsLimit && currentEnd < allnews.length' class="btn-wrap">
                                <button type="button" class="btn btn--more" @click="showMore()">Mutass többet!</button>
                            </b-col>
                        </b-row>
                    </div> 
                </b-col>
            </b-row>
        </b-container>      
        <Socialnetwork  />  
        <div class="half-left-dc"></div>   
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import NewsItemBig from '@/components/pages/NewsItemBig.vue'
import NewsItem from '@/components/pages/NewsItem.vue'
import Socialnetwork from '@/components/layout/Socialnetwork'

export default {
    mixins: [apiH],
    data(){
        return {
            allnews:[],
            featuredNews:[],
            promotions:[],
            otherNewsLimit: 4,
            otherNewsStep: 8,
            currentEnd: 4,
        }
    },
    components:{
        NewsItemBig,
        NewsItem,
        Socialnetwork
    },
    created(){
        this.loadNews()
        this.loadPromosions()
    },
    mounted(){
         this.setMetaTags({
            title: 'Kunsági Éden - Aktualitások', 
            desc: '', 
            keywords: '', 
            ogType: 'website', 
            ogTitle: 'Kunsági Éden - Aktualitások',                         
            ogImage: null, 
            ogImageWidth: null, 
            ogImageHeight: null, 
            ogDesc:''
        })
    },
    methods:{
        loadNews(){
            var _this = this;            

            _this.get('news',
                {
                    params: {                        
                        where: [['where','status', '1']],
                        order_by:[
                            ['published_at','desc']                
                        ], 
                    }
                },
                function(resp){                                        
                    _this.sortNews(resp.data.news)
                }, function(err){
                    console.log(err);
                     _this.allnews = []
                }
            )
        },
        sortNews(newslist){
            var _this = this                            
            
            newslist.forEach( (element, index) => {                                
                if ( index < 2 ){
                    _this.featuredNews.push(element)
                } else {
                    _this.allnews.push(element)
                }
            });

        },
        loadPromosions(){
            var _this = this;            

            _this.get('promotion',
                {
                    params: {                        
                        where: [['where','status', '1']],
                        order_by:[
                            ['published_at','desc']                
                        ], 
                    }
                },
                function(resp){                                        
                    _this.promotions = resp.data.promotion
                }, function(err){
                    console.log(err);
                     _this.promotions = []
                }
            )
        },
        showMore(){
            this.currentEnd += this.otherNewsStep

            this.setDataLayer('all_content_click', 'all_news_click', '')
        },
    }
}
</script>